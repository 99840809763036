import { API } from 'src/helpers/api';
// import { SERVICES } from 'src/_mock/_services';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';

import { BOT_ID } from '../config-global';

export const getVirtualCards = async (dispatch: React.Dispatch<ActionType>) => {
  try {
    const url = 'virtualCard/getMyVirtualCards';
    const { data } = await API({
      url,
      method: 'GET',
    });
    dispatch({ type: ActionTypes.GET_VIRTUAL_CARD, payload: data.payload });

    // switch to mock virtualCards
    // dispatch({ type: ActionTypes.GET_VIRTUAL_CARD, payload: SERVICES });
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const getAllVirtualCards = async (
  dispatch: React.Dispatch<ActionType>,
  smbAccountId: string
) => {
  try {
    const url = 'virtualCardType/getAllBySmbAccountId';
    const { data } = await API({
      url,
      method: 'GET',
      params: {
        botId: BOT_ID,
        active: true,
        smbAccountId,
      },
    });
    dispatch({ type: ActionTypes.GET_ALL_VIRTUAL_CARD, payload: data.payload });
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};
